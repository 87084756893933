<template>
  <div>
    <Header></Header>
    <SideLeft v-if="showSideLeft"/>
  </div>
</template>

<script>
import Header from '../layouts/Header.vue'
import SideLeft from '../layouts/SideLeft.vue'

export default {
  name: 'HomeLayout',
  components: {
    Header,
    SideLeft
  },
  computed: {
    showSideLeft () {
      const excludedRoutes = ['ProductForm', 'ThreeScene']
      const currentRouteName = this.$route.name

      return !excludedRoutes.includes(currentRouteName)
    }
  }
}
</script>

<style scoped>
</style>
